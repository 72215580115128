export const config = {
  backend: {
    apiUrl: import.meta.env.VITE_API_ENDPOINT,
  },
  userAppUrl: import.meta.env.VITE_USER_APP_URL,
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
    authKey: import.meta.env.VITE_SENTRY_AUTH_KEY,
    env: import.meta.env.VITE_SENTRY_ENV,
  },
  googleMapsToken: import.meta.env.VITE_GOOGLE_MAPS_TOKEN,
};
