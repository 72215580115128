import { PatchFERTFormData } from '@/components/forms/delivery/DeliveryFERTDeviceForm.tsx';
import { PatchSENSFormData } from '@/components/forms/delivery/DeliverySENSDeviceForm.tsx';
import { PatchSTARTFormData } from '@/components/forms/delivery/DeliverySTARTDeviceForm.tsx';
import { ErrorResponse } from '@/redux/auth/auth.types.ts';
import { ParcelUserInfo } from '@/redux/parcels/parcels.types.ts';

export type GetAvailableZonesResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: number[];
};

export type CreateDeviceResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: FetchDevice;
};

export type CreateFERTDeviceData = {
  parcelId: string;
  name: string;
  color: string;
  communicationModule: string;
  pHFactorK: number;
  pHFactorM: number;
  zonesForDevice: number[];
  supplementOne: boolean;
  supplementTwo: boolean;
  supplementThree: boolean;
  supplementFour: boolean;
  supplementFive: boolean;
  supplementMixer: boolean;
  ecSensor: boolean;
  ecFactorK: number;
  flowMeter: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
  waterLevelSensor: boolean;
  pHWater: boolean;
};

export type FetchDevice = {
  id: string;
  name: string;
  type: string;
};

export type FetchDevicesResponse = {
  userInfo: ParcelUserInfo;
  devices: FetchDevice[];
};

export type GetDevicesResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: FetchDevicesResponse;
};

export type CreateStartDeviceData = {
  parcelId: string;
  name: string;
  color: string;
  zonesForDevice: number[];
  humidityOne: boolean;
  humidityTwo: boolean;
  supplementMixer: boolean;
  flowMeter: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
};

export const deviceTypes = {
  FERT: 'FERT',
  SENS: 'SENS',
  START: 'START',
};

export const deviceTypeOptions = [
  {
    value: deviceTypes.FERT,
    text: 'Smart FERT',
  },
  {
    value: deviceTypes.START,
    text: 'Smart START',
  },
  {
    value: deviceTypes.SENS,
    text: 'Smart SENS',
  },
];

export type HumidityForDevice = {
  humidityOne: boolean;
  humidityTwo: boolean;
};

export type AdditionalSensorValuesStart = {
  supplementMixer: boolean;
  flowMeter: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
};

export type AdditionalFieldsStart = {
  rev: string;
  acRelay: number;
  mainPumpRelay24: boolean;
  mixerRelay24: boolean;
  irrigationByTime: boolean;
  irrigationByVolume: boolean;
  flowMeterType: string;
};

export type StartDevice = {
  id: string;
  parcelId: string;
  name: string;
  serialNumber: string;
  color: string;
  zonesForDevice: number[];
  humidity: HumidityForDevice;
  additionalSensorValues: AdditionalSensorValuesStart;
  additionalFields: AdditionalFieldsStart;
  commentOnEdit: string;
  did: string;
};

export type GetStartDeviceResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: StartDevice;
};

export type SupplementForDevice = {
  supplementOne: boolean;
  supplementTwo: boolean;
  supplementThree: boolean;
  supplementFour: boolean;
  supplementFive: boolean;
};

export type AdditionalSensorValuesFERT = {
  supplementMixer: boolean;
  ecSensor: boolean;
  ecFactorK: number;
  flowMeter: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
  waterLevelSensor: boolean;
};

export type AdditionalFieldsFERT = {
  rev: string;
  acRelay: number;
  mainPumpRelay24: boolean;
  mixerRelay24: boolean;
  irrigationByTime: boolean;
  irrigationByVolume: boolean;
  pHControl: boolean;
  ecControl: boolean;
  flowProportional: boolean;
  fixedPumpPowerMod2: boolean;
  pHWater: boolean;
  flowMeterType: string;
};

export type FERTDevice = {
  id: string;
  parcelId: string;
  name: string;
  serialNumber: string;
  color: string;
  communicationModule: string;
  pHFactorK: number;
  pHFactorM: number;
  zonesForDevice: number[];
  supplement: SupplementForDevice;
  additionalSensorsValues: AdditionalSensorValuesFERT;
  additionalFields: AdditionalFieldsFERT;
  commentOnEdit: string;
  did: string;
};

export type GetFERTDeviceResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: FERTDevice;
};

export type AdditionalSensorValuesSens = {
  flowMeter: boolean;
  soilTemperature: boolean;
  soilSalinity: boolean;
  soilPH: boolean;
  airTemperature: boolean;
  airHumidity: boolean;
  windDirection: boolean;
  windSpeed: boolean;
  solarRadiation: boolean;
  amountOfPrecipitation: boolean;
  batteryVoltage: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
};

export type CreateSensDevice = {
  parcelId: string;
  name: string;
  color: string;
  zonesForDevice: number[];
  standbyModeTime: number;
  sensSensorType: number;
  humidityOne: boolean;
  humidityTwo: boolean;
  flowMeter: boolean;
  soilTemperature: boolean;
  soilSalinity: boolean;
  soilPH: boolean;
  airTemperature: boolean;
  airHumidity: boolean;
  windDirection: boolean;
  windSpeed: boolean;
  solarRadiation: boolean;
  amountOfPrecipitation: boolean;
  batteryVoltage: boolean;
  notificationForWrongFlowMeter: boolean;
  pressureSensor: boolean;
  batteryType: string;
};

export type AdditionalFieldsSens = {
  rev: string;
  dcRelay: number;
  sentekTH30: boolean;
  sentekTHS30: boolean;
  sentekTH60: boolean;
  sentekTHS60: boolean;
  sentekTH90: boolean;
  sentekTHS90: boolean;
  airTempAndHumidType: string;
  dekagonHS1: boolean;
  dekagonHS2: boolean;
  irrigationByTime: boolean;
  irrigationByVolume: boolean;
  batteryType: string;
  flowMeterType: string;
};

export type SensDevice = {
  id: string;
  parcelId: string;
  serialNumber: string;
  name: string;
  color: string;
  zonesForDevice: number[];
  standbyModeTime: number;
  sensSensorType: number;
  humidity: HumidityForDevice;
  additionalSensorsValues: AdditionalSensorValuesSens;
  additionalFields: AdditionalFieldsSens;
  commentOnEdit: string;
  did: string;
};

export type GetSensDeviceResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: SensDevice;
};

export enum DeviceAvailability {
  ONLINE = 'ONLINE',
  OFFLINE = 'OFFLINE',
}

export type DevicesForUser = {
  fertDevices: FERTDevice[];
  startDevices: StartDevice[];
  sensDevices: SensDevice[];
};

export type GetDevicesForUserResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: DevicesForUser;
};

export enum DeviceType {
  FERT = 'FERT',
  START = 'START',
  SENS = 'SENS',
}

export type DeviceRevision = {
  id: string;
  revision: string;
  deviceType: DeviceType;
};

export type GetDevicesRevisionsResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: DeviceRevision[];
};

export type PatchFERTRequest = {
  device: FERTDevice;
  dataToUpdate: PatchFERTFormData;
};

export type PatchSTARTRequest = {
  device: StartDevice;
  dataToUpdate: PatchSTARTFormData;
};

export type PatchSENSRequest = {
  device: SensDevice;
  dataToUpdate: PatchSENSFormData;
};

export type DeviceLocations = {
  id: string;
  name: string;
  country: string;
  parcelId: string;
  coordinates: string;
  parcelName: string;
  organisationName: string;
  place: string;
};

export type GetDevicesLocationResponse = {
  success: boolean;
  message?: string;
  error?: ErrorResponse;
  content?: DeviceLocations[];
};

export type DeviceLocationsMap = {
  id: string;
  name: string;
  country: string;
  parcelId: string;
  coordinates: string;
  parcelName: string;
  organisationName: string;
  place: string;
  lat: number;
  lng: number;
};
